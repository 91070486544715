import {
  FripSaleInfoParam,
  Items,
  ProductParamInput,
} from 'containers/ProductRegist/hooks/types';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import {
  CurriculumKind,
  CurriculumStyle,
  FripInfo,
  InventoryTargetType,
  Item,
  Product,
  ProductHeaderContentStyle,
  ProductKind,
} from 'types/api';
import { SAVE_PRODUCT_REGEX } from './regExp';

export class ConvertingProductParamInput {
  public convertProductParam(
    param: ProductParamInput,
    scheduleList: any,
  ): ProductParamInput {
    const paramInput = {
      attributeIds: this.convertingAttributeIds(param.attributeIds, param.kind),
      cancelingRestricted: param.cancelingRestricted,
      catchphrase: param.catchphrase,
      categories: param.categories,
      exposedChannelIds: param.exposedChannelIds,
      externalProvisionIds: param.externalProvisionIds,
      grossPurchaseLimitation: param.grossPurchaseLimitation,
      headerContents: param.headerContents.map(item => {
        return {
          contentId: item.contentId,
          style: item.style,
        };
      }),
      htmlDetailContent: param.htmlDetailContent,
      inquiryTemplateId: param.inquiryTemplateId,
      inventoryTargetOptionLevel: param.inventoryTargetOptionLevel,
      inventoryTargetType: param.frip.attachedToSchedule
        ? param.inventoryTargetType
        : InventoryTargetType.BY_ITEM,
      items: this.convertingProductViewItems(
        param.items,
        param.inquiryTemplateId,
      ),
      keywords: param.keywords,
      kind: param.kind,
      maximumPurchasableCount: param.maximumPurchasableCount,
      notice: param.notice,
      options: param.options.map(item => {
        return { id: item.id, names: item.names, title: item.title };
      }),
      frip: this.convertingFripInfoView(param.frip, scheduleList),
      saleTerm: param.saleTerm,
      standardCategoryId: param.standardCategoryId,
      tagIds: param.tagIds,
      title:
        param.title === ''
          ? `(임시저장) ${dayjs().format('YYYY-MM-DD HH:mm:ss')}`
          : param.title,
      underageRestricted: param.underageRestricted,
    };

    return paramInput;
  }

  public convertProductParamInput(
    param: Product,
    scheduleList?: any,
  ): ProductParamInput {
    const paramInput = {
      attributeIds: param.attributes.map(item => item.id),
      cancelingRestricted: param.cancelingRestricted,
      catchphrase: param.catchphrase,
      categories: param.categories.map(categoryItem => {
        return {
          displayCategoryId: categoryItem.displayCategory.id,
          representative: categoryItem.representative,
        };
      }),
      exposedChannelIds: param.exposures.map(item => item.id),
      externalProvisionIds: param.externalProvisions.map(item => item.id),
      frip: this.convertingFripInfo(param.frip, scheduleList),
      grossPurchaseLimitation: param.grossPurchaseLimitation,
      headerContents: param.headerContents.map(item => {
        return {
          contentId: item.content.id,
          style: ProductHeaderContentStyle.SQUARE,
          uri: item.content.uri,
        };
      }),
      htmlDetailContent: param.contents.map(item => item.content).join(),
      inquiryTemplateId: param.inquiryTemplate
        ? param.inquiryTemplate.id
        : null,
      inventoryTargetOptionLevel: param.inventoryTargetOptionLevel,
      inventoryTargetType: param.inventoryTargetType,
      items: this.convertingItems(param.items),
      keywords: param.keywords,
      kind: param.kind,
      maximumPurchasableCount: param.maximumPurchasableCount,
      notice: param.notice,
      options: param.options.map(item => {
        return { id: item.id, names: item.names, title: item.title };
      }),
      saleTerm: param.saleTerm,
      standardCategoryId: param.standardCategories[0].id,
      tagIds: param.tags.map(item => item.id),
      title: param.title,
      underageRestricted: param.underageRestricted,
      updatedAt: param.updatedAt,
      firstOpenedAt: param.firstOpenedAt,
    };

    return paramInput;
  }

  public convertRegistProductParamInput(
    param: Product,
    scheduleList?: any,
  ): ProductParamInput {
    const paramInput = {
      id: param.id,
      attributeIds: param.attributes.map(item => item.id),
      cancelingRestricted: param.cancelingRestricted,
      catchphrase: param.catchphrase,
      categories: param.categories.map(categoryItem => {
        return {
          displayCategoryId: categoryItem.displayCategory.id,
          representative: categoryItem.representative,
        };
      }),
      exposedChannelIds: param.exposures.map(item => item.id),
      externalProvisionIds: param.externalProvisions.map(item => item.id),
      frip: this.convertingFripInfo(param.frip, scheduleList),
      grossPurchaseLimitation: param.grossPurchaseLimitation,
      headerContents: param.headerContents.map(item => {
        return {
          contentId: item.content.id,
          style: ProductHeaderContentStyle.SQUARE,
          uri: item.content.uri,
        };
      }),
      htmlDetailContent: param.contents.map(item => item.content).join(),
      inquiryTemplateId: param.inquiryTemplate
        ? param.inquiryTemplate.id
        : null,
      inventoryTargetOptionLevel: param.inventoryTargetOptionLevel,
      inventoryTargetType: param.inventoryTargetType,
      items: this.convertingRegistItems(param.items),
      keywords: param.keywords,
      kind: param.kind,
      maximumPurchasableCount: param.maximumPurchasableCount,
      notice: param.notice,
      options: param.options.map(item => {
        return { id: item.id, names: item.names, title: item.title };
      }),
      saleTerm: param.saleTerm,
      standardCategoryId: !isEmpty(param.standardCategories)
        ? param.standardCategories[0].id
        : '',
      tagIds: param.tags.map(item => item.id),
      title: SAVE_PRODUCT_REGEX.test(param.title) ? '' : param.title,
      underageRestricted: param.underageRestricted,
      updatedAt: param.updatedAt,
    };

    return paramInput;
  }

  public convertingItems(items: Item[]): Items[] {
    let itemsParam;

    if (!isEmpty(items)) {
      itemsParam = items.map(item => {
        return {
          absentFee: item.absentFee,
          commission: item.commission,
          daysOfExpiration: item.daysOfExpiration,
          description: item.description,
          digitalContent: item.digitalContent
            ? { uri: item.digitalContent.uri }
            : null,
          id: item.id,
          maximumPurchasableCount: item.maximumPurchasableCount,
          minimumQuota: item.minimumQuota,
          name: item.name,
          options: item.options.map(optionItem => {
            return {
              id: optionItem.id,
              name: optionItem.name,
              title: optionItem.title,
            };
          }),
          paramId: item.id,
          status: item.status,
          price: {
            currencyCode: item.price.currencyCode,
            discountRate: Number(item.price.discountRate),
            retail: Number(item.price.retail),
            sale: Number(item.price.sale),
          },
          quota: item.quota,
          representative: item.representative,
          saleTerm: item.saleTerm,
          salesType: item.salesType,
          scheduledBySale: item.scheduledBySale,
          ticketCount: item.ticketCount,
        };
      });
    } else {
      itemsParam = [];
    }

    return itemsParam;
  }

  public convertingRegistItems(items: Item[]): Items[] {
    let itemsParam;

    if (!isEmpty(items)) {
      itemsParam = items.map(item => {
        return {
          absentFee: item.absentFee,
          commission: item.commission,
          daysOfExpiration: item.daysOfExpiration,
          description: item.description,
          digitalContent: item.digitalContent
            ? { uri: item.digitalContent.uri }
            : null,
          id: item.id,
          maximumPurchasableCount: item.maximumPurchasableCount,
          minimumQuota: item.minimumQuota,
          name: item.name,
          options: item.options.map(optionItem => {
            return {
              id: optionItem.id,
              name: optionItem.name,
              title: optionItem.title,
            };
          }),
          paramId: item.id,
          price: {
            currencyCode: item.price.currencyCode,
            discountRate: Number(item.price.discountRate),
            retail: Number(item.price.retail),
            sale: Number(item.price.sale),
          },
          quota: item.quota,
          representative: item.representative,
          saleTerm: item.saleTerm,
          salesType: item.salesType,
          scheduledBySale: item.scheduledBySale,
          ticketCount: item.ticketCount,
        };
      });
    } else {
      itemsParam = [];
    }

    return itemsParam;
  }

  public convertingProductViewItems(
    items: Items[],
    inquiryTemplateId: string,
  ): Items[] {
    let itemsParam;

    if (!isEmpty(items)) {
      itemsParam = items.map(item => {
        return {
          absentFee: item.absentFee,
          commission: item.commission,
          daysOfExpiration: item.daysOfExpiration,
          description: item.description,
          digitalContent: item.digitalContent
            ? { uri: item.digitalContent.uri }
            : null,
          id: item.id,
          maximumPurchasableCount: item.maximumPurchasableCount,
          minimumQuota: item.minimumQuota,
          name: item.name,
          options:
            item.options.length > 1
              ? item.options.map(optionItem => {
                  return {
                    id: optionItem.id,
                    name: optionItem.name,
                    title: optionItem.title,
                  };
                })
              : item.options.map(optionItem => {
                  return {
                    id: optionItem.id,
                    name: item.name,
                    title: optionItem.title,
                  };
                }),
          paramId: item.paramId,
          price: {
            currencyCode: item.price.currencyCode,
            discountRate: Number(item.price.discountRate),
            retail: Number(item.price.retail),
            sale: Number(item.price.sale),
          },
          quota: item.quota,
          representative: item.representative,
          saleTerm: item.saleTerm,
          salesType: item.salesType,
          scheduledBySale: item.scheduledBySale,
          ticketCount: item.ticketCount,
          inquiryTemplateId,
        };
      });
    } else {
      itemsParam = [];
    }

    return itemsParam;
  }

  public convertingFripInfo(
    fripInfo: FripInfo,
    scheduleList?: any,
  ): FripSaleInfoParam {
    const locationGathering = fripInfo.locationOfGathering;

    const today = new Date();

    const ninetyDaysLater = new Date();
    ninetyDaysLater.setDate(today.getDate() + 90);

    const param = {
      attachedToSchedule: fripInfo.attachedToSchedule,
      bookingApprovalEnabled: fripInfo.bookingApprovalEnabled,
      bookingConfirmationEnabled: fripInfo.bookingConfirmationEnabled,
      curriculum: {
        ...fripInfo.curriculum,
        sections: fripInfo.curriculum.sections.map(sectionItem => {
          return {
            id: sectionItem.id,
            title: sectionItem.title,
            items: sectionItem.items,
            etc: sectionItem.etc,
            isOpenEtc: sectionItem.etc !== '',
          };
        }),
      },
      schedulingTerm: {
        startedAt: today.getTime(),
        endedAt: ninetyDaysLater.getTime(),
      },
      daysOfExpiration: fripInfo.daysOfExpiration,
      difficulty: fripInfo.difficulty,
      exclusions: fripInfo.exclusions,
      inclusions: fripInfo.inclusions,
      isIndoor: fripInfo.isIndoor,
      isOutdoor: fripInfo.isOutdoor,
      kind: fripInfo.kind,
      companions: fripInfo.companions,
      interactionType: fripInfo.interactionType,
      locationOfGathering: locationGathering
        ? {
            id: locationGathering.id,
            address: locationGathering.address,
            geoPoint: locationGathering.geoPoint,
            label: locationGathering.label,
            locationId:
              locationGathering.location !== null
                ? locationGathering.location.id
                : null,
            overseas: locationGathering.overseas,
          }
        : null,
      locationsOfVenue: fripInfo.locationsOfVenue.map(locationItem => {
        return {
          id: locationItem.id,
          address: locationItem.address,
          geoPoint: locationItem.geoPoint,
          label: locationItem.label,
          locationId: locationItem.location ? locationItem.location.id : null,
          overseas: locationItem.overseas,
        };
      }),
      plans: fripInfo.plans
        ? fripInfo.plans.map(item => {
            return {
              appliedAllItems: item.appliedAllItems,
              id: item.id,
              minimumQuota: item.minimumQuota,
              name: item.name,
              overNight: item.overNight,
              paramId: item.id,
              preparatoryPeriod: item.preparatoryPeriod,
              quota: item.quota,
              targetItemParamIds: item.targetItems.map(item => item.id),
              term: item.term,
              times: item.times.map(timeItem => {
                return {
                  duration: timeItem.duration,
                  starting: timeItem.starting,
                };
              }),
              weeks: item.weeks,
            };
          })
        : [],
      recommendedAge: fripInfo.recommendedAge,
      schedules: scheduleList
        ? scheduleList.map(item => {
            return {
              id: item.id,
              appliedAllItems: item.appliedAllItems,
              itemParamIds: item.items.map(items => items.id),
              maximumPurchasableCount: item.maximumPurchasableCount,
              minimumQuota: item.minimumQuota,
              quota: item.quota,
              planParamId: item.plan ? item.plan.id : '',
              saleTerm: {
                startedAt: item.saleTerm.startedAt,
                endedAt: item.saleTerm.endedAt,
              },
              term: {
                startedAt: item.term.startedAt,
                duration: item.term.duration,
              },
              title: item.title,
              status: item.status,
            };
          })
        : [],
      stuffsToPrepare: fripInfo.stuffsToPrepare,
    };

    return param;
  }

  public convertingFripInfoView(
    fripInfo: FripSaleInfoParam,
    scheduleList?: any,
  ): FripSaleInfoParam {
    const locationGathering = fripInfo.locationOfGathering;

    const param = {
      attachedToSchedule: fripInfo.attachedToSchedule,
      bookingApprovalEnabled: false,
      bookingConfirmationEnabled: fripInfo.bookingConfirmationEnabled,
      curriculum: isEmpty(fripInfo.curriculum.sections)
        ? {
            kind: CurriculumKind.SCHEDULE,
            sections: [],
            style: CurriculumStyle.NONE,
          }
        : {
            ...fripInfo.curriculum,
            sections: fripInfo.curriculum.sections.map(sectionItem => {
              return {
                id: sectionItem.id,
                title: sectionItem.title,
                items: sectionItem.items,
                etc: sectionItem.etc,
              };
            }),
          },
      schedulingTerm: fripInfo.schedulingTerm,
      daysOfExpiration: fripInfo.daysOfExpiration,
      difficulty: fripInfo.difficulty,
      exclusions: fripInfo.exclusions,
      inclusions: fripInfo.inclusions,
      isIndoor: fripInfo.isIndoor,
      isOutdoor: fripInfo.isOutdoor,
      kind: fripInfo.kind,
      companions: fripInfo.companions,
      interactionType: fripInfo.interactionType,
      locationOfGathering: locationGathering
        ? {
            id: locationGathering.id,
            address: locationGathering.address,
            geoPoint: locationGathering.geoPoint,
            label: locationGathering.label,
            locationId: locationGathering.locationId,
            overseas: locationGathering.overseas,
          }
        : null,
      locationsOfVenue: fripInfo.locationsOfVenue.map(locationItem => {
        return {
          id: locationItem.id,
          address: locationItem.address,
          geoPoint: {
            latitude: locationItem.geoPoint.latitude,
            longitude: locationItem.geoPoint.longitude,
          },
          label: locationItem.label,
          locationId: locationItem.locationId,
          overseas: locationItem.overseas,
        };
      }),
      plans: fripInfo.plans
        ? fripInfo.plans.map(item => {
            return {
              appliedAllItems: item.appliedAllItems,
              id: item.id,
              minimumQuota: item.minimumQuota,
              name: item.name,
              overNight: item.overNight,
              paramId: item.paramId,
              preparatoryPeriod: item.preparatoryPeriod,
              quota: item.quota,
              targetItemParamIds: item.targetItemParamIds,
              term: item.term,
              times: item.times.map(timeItem => {
                return {
                  duration: timeItem.duration,
                  starting: timeItem.starting,
                };
              }),
              weeks: item.weeks,
            };
          })
        : [],
      recommendedAge: fripInfo.recommendedAge,
      schedules: fripInfo.schedules
        ? fripInfo.schedules.map(item => {
            return {
              id: item.id,
              appliedAllItems: item.appliedAllItems,
              itemParamIds: item.itemParamIds,
              maximumPurchasableCount: item.maximumPurchasableCount,
              minimumQuota: item.minimumQuota,
              quota: item.quota,
              planParamId: null,
              saleTerm: {
                startedAt: item.saleTerm.startedAt,
                endedAt: item.saleTerm.endedAt,
              },
              term: {
                startedAt: item.term.startedAt,
                duration: item.term.duration,
              },
              title: item.title,
            };
          })
        : [],
      // ? scheduleList.map(item => {
      //     return {
      // id: item.id,
      // appliedAllItems: item.appliedAllItems,
      // itemParamIds: item.items.map(items => items.id),
      // maximumPurchasableCount: item.maximumPurchasableCount,
      // minimumQuota: item.minimumQuota,
      // quota: item.quota,
      // planParamId: item.plan ? item.plan.id : null,
      // saleTerm: {
      //   startedAt: item.saleTerm.startedAt,
      //   endedAt: item.saleTerm.endedAt,
      // },
      // term: {
      //   startedAt: item.term.startedAt,
      //   duration: item.term.duration,
      // },
      // title: item.title,
      //     };
      //   })
      // : [],
      stuffsToPrepare: fripInfo.stuffsToPrepare,
    };

    return param;
  }

  public convertingAttributeIds(
    attributeIds: string[],
    kind: ProductKind,
  ): string[] {
    let attributeParams: string[] = [];

    if (kind === ProductKind.ONLINE) {
      attributeParams = attributeIds.map(item => {
        if (Number(item) > 4) {
          return item;
        } else {
          return (Number(item) + 4).toString();
        }
      });
    } else {
      attributeParams = attributeIds;
    }

    return attributeParams;
  }
}
